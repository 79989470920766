import { CF_SITE_LOCALE } from '@constants/cloudflare';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';
import FocusTrap from 'focus-trap-react';
import Cookies from 'js-cookie';
import { NextRouter, useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useMemo } from 'react';

import {
  L360_MAIN_LOCALES_MAP,
  L360_SECONDARY_LOCALES_MAP,
  LocaleMap,
  LocaleMapData,
  MAIN_LOCALES_MAP,
  SECONDARY_LOCALES_MAP,
} from '@components/common/I18nSelector/constants';
import Button from '@components/ui/Button/Button';
import { useUI } from '@components/ui/context';
import { ModalView } from '@components/ui/Modal/Modal';
import Text from '@components/ui/Text/Text';
import useCart from '@framework/cart/use-cart';
import { useLocale } from '@lib/hooks/useLocale';
import { isLife360Enabled } from '@lib/storefront';

import s from './I18nDialogSelector.module.scss';

interface Props {
  onClick?: () => void;
  onClose?: () => void;
}

type LocaleData = { locale: string; country: string; lang: string; url?: string };

const MAIN_COLUMNS_NUMBER = 4;
const SECONDARY_COLUMNS_NUMBER = 3;

const getExternalUrl = (lang: string, country: string) => `https://${country}.tile.com/${lang}`;

const redirectUrl = (router: NextRouter, localeData: LocaleData) => {
  const localeExists = router.locales?.find((l) => l === localeData.locale);
  if (localeExists) {
    Cookies.set(CF_SITE_LOCALE, localeData.locale, { expires: 1 });
    router.push('/', '', { locale: localeData.locale });
  } else if (localeData.url) {
    router.push(localeData.url);
  } else {
    router.push(getExternalUrl(localeData.lang, localeData.country));
  }
};

const useCountries = (locales: LocaleMap): [shortName: string, country: LocaleMap[0]][] =>
  useMemo(() => Object.entries(locales), [locales]);

const getTemplateRows = (length: number, numberOfLines: number) => ({
  gridTemplateRows: `repeat(${Math.round(length / numberOfLines)}, auto)`,
  gridTemplateColumns: `repeat(${numberOfLines}, auto)`,
});

interface RegionSwitcherConfirmationProps extends ModalView {
  localeData: LocaleData;
  closeDialogSelector?: () => void;
}

export const RegionSwitcherConfirmation: FC<RegionSwitcherConfirmationProps> = ({
  localeData,
  closeDialogSelector,
  closeModal,
}) => {
  const { t } = useTranslation('common');
  const ui = useUI();
  const router = useRouter();

  const handleClick = () => {
    redirectUrl(router, localeData);

    closeDialogSelector?.();
    closeModal?.();
  };

  return (
    <div>
      <Text className="text-center mb-5" html={t('languageSelector.dialogSubtitle')} variant="text-3" />
      <div className="flex justify-center items-center">
        <Button variant="slim" type="button" onClick={ui.closeModal}>
          {t('cancel')}
        </Button>
        <Button
          className={s.dialogConfirm}
          data-cy="languageChangeConfirm"
          variant="cta"
          type="button"
          onClick={handleClick}
        >
          <Text variant="heading-5">{t('confirm')}</Text>
        </Button>
      </div>
    </div>
  );
};

interface CountryItemProps {
  shortName: string;
  data: LocaleMapData;
  smallIcon?: boolean;
  onClick?: (obj: LocaleData) => void;
}

const CountryItem: FC<CountryItemProps> = ({ shortName, data, onClick, smallIcon }) => {
  const { t } = useTranslation('languages');

  return (
    <li className={s.countryItem}>
      <Text variant="text-4" className="flex items-center">
        <span aria-hidden className={!smallIcon ? 'text-2xl' : 'text-3xl'}>
          {data.img}
        </span>
        <span>
          <small>&nbsp; &nbsp;</small>
          <b>{t(`countries:countries.${shortName}`)} |&nbsp;</b>
        </span>
      </Text>
      <ul>
        {data.lang.map((l, i) => (
          <li key={l} className="inline-block">
            <button
              className={s.langLink}
              data-cy={`locale-${l}-${shortName}`.toLocaleLowerCase()}
              type="button"
              onClick={() =>
                onClick?.({
                  locale: `${l}-${shortName}`.toLocaleLowerCase(),
                  country: shortName,
                  lang: l,
                  url: data.url?.[i],
                })
              }
            >
              <abbr title={t(l)} aria-hidden>
                {l}
              </abbr>
              <span className="sr-only">{t(l)}</span>
            </button>
            {i < data.lang.length - 1 && <span> /&nbsp;</span>}
          </li>
        ))}
      </ul>
    </li>
  );
};

interface CountryListProps {
  locales: [shortName: string, country: LocaleMap[0]][];
  numberOfLines: number;
  smallIcons?: boolean;
  onClose?: () => void;
  onLocaleChange?: (localeData: LocaleData) => void;
}

const CountryList: FC<CountryListProps> = ({ locales, numberOfLines, onClose, onLocaleChange, smallIcons }) => {
  const { locale } = useLocale();

  if (!locales) {
    return null;
  }

  const handleCountryItemClick = (localeData: LocaleData) => {
    if (localeData.locale === locale) {
      onClose?.();
      return;
    }

    onLocaleChange?.(localeData);
  };

  return (
    <ul className={s.countryList} style={getTemplateRows(locales?.length, numberOfLines)}>
      {locales.map(([shortName, c]) => (
        <CountryItem
          key={shortName}
          shortName={shortName}
          data={c}
          smallIcon={smallIcons}
          onClick={handleCountryItemClick}
        />
      ))}
    </ul>
  );
};

const I18nDialogSelector: FC<Props> = ({ onClick, onClose }) => {
  const { t } = useTranslation('common');
  const ui = useUI();
  const cart = useCart();
  const router = useRouter();
  const mainCountries = useCountries(isLife360Enabled() ? L360_MAIN_LOCALES_MAP : MAIN_LOCALES_MAP);
  const secondaryCountries = useCountries(isLife360Enabled() ? L360_SECONDARY_LOCALES_MAP : SECONDARY_LOCALES_MAP);

  useEffect(() => {
    const mainContent = document.querySelector('#mainContent');
    if (mainContent) {
      disableBodyScroll(mainContent, {
        // iOS scroll bug: see https://github.com/willmcpo/body-scroll-lock#allowtouchmove
        allowTouchMove: (el) => el.className.includes('I18nDialogSelector'),
      });
    }

    return clearAllBodyScrollLocks;
  }, []);

  const handleLocaleChange = (localeData: LocaleData) => {
    if (cart.isEmpty) {
      redirectUrl(router, localeData);
      onClick?.();
      return;
    }

    ui.setModal('SWITCH_REGION', { localeData, closeDialogSelector: onClick }, cn(s.modal));
    ui.openModal();
  };

  return (
    <FocusTrap
      focusTrapOptions={{
        tabbableOptions: { displayCheck: 'none' },
        clickOutsideDeactivates: true,
      }}
    >
      <div className={cn(s.root, 'I18nDialogSelector')} data-testid="I18nDialogSelector">
        <div className={s.titleContainer}>
          <Text className={s.title} variant="heading-2">
            {t('languageSelector.chooseYourCountry')}
          </Text>
          <button
            aria-label={t('languageSelector.closePanel')}
            className={s.closeButton}
            type="button"
            onClick={onClose}
          >
            <FontAwesomeIcon className="mr-xs text-secondary" icon={faTimes} size="lg" />
          </button>
        </div>

        <div className={s.bodyContainer}>
          <CountryList
            locales={mainCountries}
            numberOfLines={MAIN_COLUMNS_NUMBER}
            onClose={onClose}
            onLocaleChange={handleLocaleChange}
          />
          <CountryList
            locales={secondaryCountries}
            numberOfLines={SECONDARY_COLUMNS_NUMBER}
            smallIcons
            onClose={onClose}
            onLocaleChange={handleLocaleChange}
          />
        </div>
      </div>
    </FocusTrap>
  );
};

export default I18nDialogSelector;
